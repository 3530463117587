// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-property-detail-js": () => import("../src/components/property-detail.js" /* webpackChunkName: "component---src-components-property-detail-js" */),
  "component---src-components-property-images-js": () => import("../src/components/property-images.js" /* webpackChunkName: "component---src-components-property-images-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-js": () => import("../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-recruit-js": () => import("../src/pages/recruit.js" /* webpackChunkName: "component---src-pages-recruit-js" */)
}

